<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  error: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  name: {
    type: String,
    default: () => Math.random().toString(36).substring(2, 15),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "blur"]);

watch(props, () => {
  // console.log(props.modelValue);
  if (props.type === "url") {
    // value.value longer than 8 and doesn't include http or https add https
    if (
      props.modelValue.split("").includes(".") &&
      !props.modelValue.includes("http") &&
      !props.modelValue.includes("https")
    ) {
      emit("update:modelValue", `https://${props.modelValue}`);
    }
  }
});
</script>

<template>
  <div class="input-grid group h-fit w-full">
    <label
      class="grid-label block text-xs leading-6 text-input-label text-opacity-80"
      :for="props.label"
    >
      {{ props.label }}
    </label>
    <div class="grid-left mr-3" :class="{ hidden: !$slots.left }">
      <slot name="left" />
    </div>
    <div
      class="grid-center z-10 inline-flex w-full items-center gap-x-3 overflow-hidden rounded-xl bg-white ring-1 ring-inset ring-input group-hover:ring-input-hover"
    >
      <div class="relative min-w-0 flex-[1]">
        <div
          :id="props.label"
          class="h-[44px] w-full cursor-not-allowed truncate rounded-xl bg-transparent px-5 py-3 font-normal outline-none"
        >
          {{ props.modelValue }}
        </div>
      </div>
      <slot name="inside" />
    </div>
    <div class="grid-right ml-3" :class="{ hidden: !$slots.right }">
      <slot name="right" />
    </div>
    <div class="grid-bottom">
      <p v-if="error.length" class="text-sm text-red-500">
        {{ error[0] }}
      </p>
      <slot v-else name="bottom" />
    </div>
  </div>
</template>

<style scoped>
.input-grid {
  display: grid;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 44px auto;
  grid-template-areas: ". label ." "left center right" ". bottom .";
  /* gap: 0px; */
  @apply gap-y-1;
}

.grid-label {
  grid-area: label;
}

.grid-left {
  grid-area: left;
}

.grid-center {
  grid-area: center;
}

.grid-right {
  grid-area: right;
}

.grid-bottom {
  grid-area: bottom;
}
</style>
